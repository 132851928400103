import { graphql } from 'gatsby'
import * as React from 'react'

import { Layout } from 'components'
import SEO from 'components/SEO'

const Pricing: React.FC<any> = props => {
    return (
        <Layout
            content={<PricingContent {...props} />}
            seo={<SEO title={'home'} />}
        />
    )
}

const PricingContent: React.FC<any> = props => {
    return (
        <React.Fragment>
            <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
                PRICING
            </main>
        </React.Fragment>
    )
}

export default Pricing

// export const query = graphql`
//     query PricingQuery {
//         strapiPricing {
//             title_en
//             heroImage {
//                 id
//                 childImageSharp {
//                     fluid {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//         }
//     }
// `
